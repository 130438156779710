import { Component, OnInit } from '@angular/core';
import { Article } from 'src/app/models/article.model';
import { DefaultCategories } from 'src/app/shared/constants/constants';
import { ApiEndPoints } from 'src/app/shared/config/ApiEndPoints';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { PaginationResponse } from 'src/app/models/pagination-response.model';
import * as _ from "lodash";

@Component({
  selector: 'app-service-section',
  templateUrl: './service-section.component.html',
  styleUrls: ['./service-section.component.scss']
})
export class ServiceSectionComponent implements OnInit {

  articles: Article[] = [];
  serviceArticleCategory = DefaultCategories["ARTICLE-SERVICE"];

  constructor(private readonly httpBaseService: HttpBaseService, private readonly api: ApiEndPoints) { }

   async ngOnInit() {
    const articlePaging = await this.httpBaseService.getDataAsync<PaginationResponse<Article>>(this.api.getArticles(this.serviceArticleCategory, 'Title', 'asc', 0, 3));
    this.articles = _.orderBy(articlePaging.items, ['index'],['asc']);
  }

  toUpper(text: string){
    return text.toUpperCase();
  }

}
