<!-- ======= Footer ======= -->
<footer id="footer">

  <!-- <div class="footer-newsletter">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <h4>Join Our Newsletter</h4>
            <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
            <form action="" method="post">
              <input type="email" name="email"><input type="submit" value="Subscribe">
            </form>
          </div>
        </div>
      </div>
    </div> -->

  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 footer-contact">
          <h3>BizPrinty<span>.</span></h3>
          <p>
            {{contactSetting?contactSetting.address:''}} <br><br>
            <strong>Phone: </strong> <a
              href="tel:{{contactSetting?contactSetting.mobilePhone:''}}">{{contactSetting?contactSetting.mobilePhone:''}}</a><br>
            <strong>Email: </strong> <a
              href="mailto:{{contactSetting?contactSetting.email:''}}">{{contactSetting?contactSetting.email:''}}</a><br>
          </p>
        </div>

        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">About us</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">Services</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Our Services</h4>
          <ul *ngIf="articles.length > 0">
            <li *ngFor="let article of articles"><i class="bx bx-chevron-right"></i> <a
                routerLink="/app/article-detail/{{article.id}}">{{article.title}}</a></li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Our Social Networks</h4>
          <!-- <p>Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies</p> -->
          <div class="social-links mt-3">
            <a href="{{contactSetting?contactSetting.twitter:''}}" class="twitter"><i class="bx bxl-twitter"></i></a>
            <a href="{{contactSetting?contactSetting.facebook:''}}" class="facebook"><i class="bx bxl-facebook"></i></a>
            <a href="{{contactSetting?contactSetting.instagram:''}}" class="instagram"><i
                class="bx bxl-instagram"></i></a>
            <a href="{{contactSetting?contactSetting.skype:''}}" class="google-plus"><i class="bx bxl-skype"></i></a>
            <a href="{{contactSetting?contactSetting.linkedin:''}}" class="linkedin"><i class="bx bxl-linkedin"></i></a>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="container py-4">
    <div class="credits">
      <!-- All the links in the footer should remain intact. -->
      <!-- You can delete the links only if you purchased the pro version. -->
      <!-- Licensing information: https://bootstrapmade.com/license/ -->
      <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/bizland-bootstrap-business-template/ -->
      Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> | Powred by <a
        href="https://onteksolutions.com">OnTek</a>
    </div>
  </div>
</footer><!-- End Footer -->