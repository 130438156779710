import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import * as _ from "lodash";
import { HttpBaseService } from '../../services/http-base.service';
import { ApiEndPoints } from '../../config/ApiEndPoints';
import { DefaultCategories } from '../../constants/constants';
import { Article } from 'src/app/models/article.model';
import { PaginationResponse } from 'src/app/models/pagination-response.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  userProfile: object;
  userName: string = '';
  userRole: string = '';
  loggedin: boolean = false;

  articles: Article[] = [];
  serviceArticleCategory = DefaultCategories["ARTICLE-SERVICE"];

  constructor(private authService: AuthService, private readonly configurationService: ConfigurationService, private readonly http: HttpBaseService, private readonly api: ApiEndPoints) {
    this.authService.isAuthenticated$.subscribe(authenticated => {
      if (authenticated) {
        this.loggedin = true;
        this.userProfile = this.authService.identityClaims;
        if (this.userProfile['oid']) {
          this.getUserInfo();          
        }
      }
    });
  }

  async getUserInfo(){
    this.userProfile = await this.http.getDataAsync<any>(this.api.getUserInfo());
    this.userName = 'Welcome ' + this.userProfile['userName'];
    this.userRole = this.userProfile['role'];
  }

  async ngOnInit(){
    this.configure();
    this.authService.tryLoginImplicitFlow();

    const articlePaging = await this.http.getDataAsync<PaginationResponse<Article>>(this.api.getArticles(this.serviceArticleCategory, 'Title', 'asc', 0, 3));
    this.articles = _.orderBy(articlePaging.items, ['index'],['asc']);
  }

  private configure() {
    this.authService.loadDiscoveryDocumentAndTryLogin();
  }

  async becomeSeller() {
    await this.http.putDataAsync<any>(this.api.becomeSeller(), undefined);
    this.logout();
    this.signinsignup();
  }

  dashboard() {
    const win = window.open(this.configurationService.dashboardUrl, '_blank');
    if (win) {
      win.focus();
    }
  }

  admin() {
    const win = window.open(this.configurationService.adminUrl, '_blank');
    if (win) {
      win.focus();
    }
  }

  factory(){
    const win = window.open(this.configurationService.factoryUrl, '_blank');
    if (win) {
      win.focus();
    }
  }

  signinsignup() {
    this.authService.login('/app');
  }

  logout() {
    this.authService.logout();
  }

  scroll(id: string) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior: 'smooth'});
  }
}
