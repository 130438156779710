export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";
export const FORMAT_ONLY_DATE = "YYYY-MM-DD";
export const DEFAULT_CONTACT_SETTING_ID = '309ee5be-cd88-44f3-99c5-aba98ab5afe5';

export const PagingConstant = {
  pageIndex: 0,
  pageNumber: 1,
  pageSize: 5,
  pageSizeLarger: 10,
  pageSizeOptions: [5, 10, 30],
  pageSizeOptionsLarger: [10, 20, 30]
}

export const StatusCodeConstant = {
  conflict: 409,
  badRequest: 400,
}

export const DefaultCategories = {
  ['ARTICLE-SERVICE']: '26EB490A-C6EC-47FB-0F70-08D8E3F0E84F',
  ['MAIN-FEATURE-ARTICLE-SERVICE']: 'bf4b7418-1f33-4804-accb-cdc79732343f',
  ['ABOUT-US-ARTICLE']: '09ec74e6-c7f5-4d3c-aee3-29d3859c19c6',
  ['INTRODUCTION-ARTICLE']: 'fdca9ad7-b41f-43e9-a844-97e9c315ffae'
}
