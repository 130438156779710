import { Component, OnInit } from '@angular/core';
import { ContactSetting } from 'src/app/models/contact-setting.model';
import { HttpBaseService } from '../../services/http-base.service';
import { ApiEndPoints } from '../../config/ApiEndPoints';
import { DEFAULT_CONTACT_SETTING_ID, DefaultCategories } from '../../constants/constants';
import { Article } from 'src/app/models/article.model';
import { PaginationResponse } from 'src/app/models/pagination-response.model';
import * as _ from "lodash";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  contactSetting: ContactSetting;
  articles: Article[] = [];
  serviceArticleCategory = DefaultCategories["ARTICLE-SERVICE"];

  constructor(private readonly httpBaseService: HttpBaseService,
    private readonly api: ApiEndPoints,) { }

  async ngOnInit() {
    this.contactSetting = await this.httpBaseService.getDataAsync<ContactSetting>(this.api.getContactSetting(DEFAULT_CONTACT_SETTING_ID));
    const articlePaging = await this.httpBaseService.getDataAsync<PaginationResponse<Article>>(this.api.getArticles(this.serviceArticleCategory, 'Title', 'asc', 0, 3));
    this.articles = _.orderBy(articlePaging.items, ['index'],['asc']);
  }

}
