<!-- ======= Header ======= -->
<header id="header" class="fixed-top">
  <div class="container d-flex align-items-center">

    <h1 class="logo mr-auto"><a href="index.html">BizPrinty<span>.</span></a></h1>
    <!-- Uncomment below if you prefer to use an image logo -->
    <!-- <a href="index.html" class="logo mr-auto"><img src="assets/img/logo.png" alt=""></a>-->

    <nav class="nav-menu d-none d-lg-block">
      <ul>
        <li class="active"><a href="index.html">Home</a></li>
        <li class="drop-down"><a href="#services">Services</a>
          <ul *ngIf="articles.length > 0">
            <li *ngFor="let article of articles"><a routerLink="/app/article-detail/{{article.id}}">{{article.title}}</a></li>
          </ul>
        </li>
        <li><a href="#blog">Blogs</a></li>
        <li><a (click)="scroll('about')">About</a></li>
        <li><a (click)="scroll('team')">Team</a></li>
        <li><a (click)="scroll('contact')">Contact</a></li>
        <li></li>
        <li *ngIf="!loggedin" (click)="signinsignup()"><span class="sign-up">signup/signin</span></li>
        <li *ngIf="loggedin" class="drop-down"><span class="sign-up">{{userName}}</span>
          <ul>
            <li *ngIf="userRole === 'HO.SYSADMIN' || userRole === 'SELLER.USER'" (click)="dashboard()"><a class="sign-up">Seller</a></li>
            <li *ngIf="userRole === 'HO.SYSADMIN' || userRole === 'VENDOR.USER'" (click)="factory()"><a class="sign-up">Factory</a></li>
            <li *ngIf="userRole === 'HO.SYSADMIN' || userRole === 'ADMIN.USER'" (click)="admin()"><a class="sign-up">Administrator</a></li>
            <li *ngIf="userRole === 'GUEST.USER'" (click)="becomeSeller()"><a class="sign-up"><strong>Become a Seller!</strong></a></li>
            <li (click)="logout()"><a class="sign-up">Log Out</a></li>
          </ul>
        </li>
      </ul>
    </nav><!-- .nav-menu -->

  </div>
</header><!-- End Header -->