<section id="faq" class="faq section-bg">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>F.A.Q</h2>
        <h3>Frequently Asked <span>Questions</span></h3>
        <p>With our passions, we are happy to support you every time you need it!</p>
      </div>

      <ul class="faq-list" data-aos="fade-up" data-aos-delay="100" *ngIf="questions.length > 0">

        <li *ngFor="let question of questions">
          <a data-toggle="collapse" class="" href="#faq1">{{question.question}}<i class="icofont-simple-up"></i></a>
          <div id="faq1" class="collapse show" data-parent=".faq-list">
            <p>{{question.answer}}
            </p>
          </div>
        </li>
      </ul>

    </div>
  </section>
