import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OAuthModule, OAuthModuleConfig, AuthConfig, ValidationHandler, OAuthStorage, JwksValidationHandler } from 'angular-oauth2-oidc';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthGuardService } from './services/auth-guard.service';
import { RedirectGuard } from './services/redirect-guard.service';

import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './components/home/home.component';
import { TopbarComponent } from './shared/components/topbar/topbar.component';
import { IntroComponent } from './shared/components/intro/intro.component';
import { FeaturedServiceComponent } from './components/featured-service/featured-service.component';
import { AboutSectionComponent } from './components/about-section/about-section.component';
import { SkillSectionComponent } from './components/skill-section/skill-section.component';
import { CountSectionComponent } from './components/count-section/count-section.component';
import { ClientSectionComponent } from './components/client-section/client-section.component';
import { ServiceSectionComponent } from './components/service-section/service-section.component';
import { TestimonialSectionComponent } from './components/testimonial-section/testimonial-section.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { TeamSectionComponent } from './components/team-section/team-section.component';
import { PricingSectionComponent } from './components/pricing-section/pricing-section.component';
import { FrequentlyAskComponent } from './components/frequently-ask/frequently-ask.component';
import { ContactSectionComponent } from './components/contact-section/contact-section.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { StandardHeaderInterceptor } from './shared/interceptors/standard-header.interceptor';
import { ArticleDetailComponent } from './components/article-detail/article-detail.component';
import { CatalogDetailComponent } from './components/catalog-detail/catalog-detail.component';
import { SafeHtmlPipe } from './shared/pipe/sanitize-html.pipe';

export function storageFactory(): OAuthStorage {
  return sessionStorage;
}

const appInitializerFn = (configurationService: ConfigurationService) => {
  return () => {
    return configurationService
      .loadRuntimeConfig()
      .then( async clientConfiguration => {
        await configurationService.loadConfig(clientConfiguration.apiUrl)
        }
      );
  };
};

@NgModule({
  declarations: [
    SafeHtmlPipe,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AppComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    TopbarComponent,
    IntroComponent,
    FeaturedServiceComponent,
    AboutSectionComponent,
    SkillSectionComponent,
    CountSectionComponent,
    ClientSectionComponent,
    ServiceSectionComponent,
    TestimonialSectionComponent,
    PortfolioComponent,
    TeamSectionComponent,
    PricingSectionComponent,
    FrequentlyAskComponent,
    ContactSectionComponent,
    ArticleDetailComponent,
    CatalogDetailComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [],
        sendAccessToken: true
      }
    }),
    AppRoutingModule,
  ],
  providers: [
    ConfigurationService,
    { provide: APP_INITIALIZER, useFactory: appInitializerFn, multi: true, deps: [ConfigurationService] },
    AuthGuardService,
    RedirectGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StandardHeaderInterceptor,
      multi: true,
    },
    { provide: OAuthStorage, useFactory: storageFactory }
  ],
  entryComponents: [
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  
}

