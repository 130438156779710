
  <main id="main" data-aos="fade-up">

    <!-- ======= Breadcrumbs Section ======= -->
    <section class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <h2>Catalog Details</h2>
          <ol>
            <li><a href="#">Home</a></li>
            <li><a href="portfolio.html">Catalog</a></li>
            <li>Catalog Details</li>
          </ol>
        </div>

      </div>
    </section><!-- Breadcrumbs Section -->

    <!-- ======= Portfolio Details Section ======= -->
    <section class="portfolio-details">
      <div class="container">

        <div class="portfolio-details-container">

          <div class="owl-carousel portfolio-details-carousel">
            <img src="../../../assets/img/portfolio/portfolio-details-1.jpg" class="img-fluid" alt="">
            <img src="../../../assets/img/portfolio/portfolio-details-2.jpg" class="img-fluid" alt="">
            <img src="../../../assets/img/portfolio/portfolio-details-3.jpg" class="img-fluid" alt="">
          </div>

          <div class="portfolio-info">
            <h3>Product information</h3>
            <ul>
              <li><strong>Category</strong>: T-Shirt</li>
              <li><strong>Provider</strong>: ASU Company</li>
              <li><strong>Price</strong>: 10$</li>
            </ul>
          </div>

        </div>

        <div class="portfolio-description">
          <h2>This is an example of product detail</h2>
          <p>
            Autem ipsum nam porro corporis rerum. Quis eos dolorem eos itaque inventore commodi labore quia quia. Exercitationem repudiandae officiis neque suscipit non officia eaque itaque enim. Voluptatem officia accusantium nesciunt est omnis tempora consectetur dignissimos. Sequi nulla at esse enim cum deserunt eius.
          </p>
        </div>
      </div>
    </section><!-- End Portfolio Details Section -->

  </main><!-- End #main -->

