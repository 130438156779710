import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { RedirectGuard } from './services/redirect-guard.service';
import { ArticleDetailComponent } from './components/article-detail/article-detail.component';
import { CatalogDetailComponent } from './components/catalog-detail/catalog-detail.component';

const routes: Routes = [
  { path: '', redirectTo: '/app', pathMatch: 'full' },
  {
    path: 'app',
    data: {},
    children: [
      {
        path: '', component: HomeComponent,
        // canActivate: [AuthGuardService],
        data: {},
        pathMatch: 'full'
      },
      {
        path: 'article-detail/:id', component: ArticleDetailComponent
      },
      {
        path: 'catalog-detail/:id', component: CatalogDetailComponent
      }
    ]
  },
  {
    path: 'should-login',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://bhpt072.b2clogin.com/bhpt072.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_signupsignin&client_id=9a3d18a2-a2dc-4447-8315-a7fb1c2488fc&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A4200&scope=openid%20profile%20https%3A%2F%2Fbhpt072.onmicrosoft.com%2Fland-api%2Fuser_impersonation&response_type=id_token&prompt=login'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthService,
    AuthGuardService
  ]
})
export class AppRoutingModule { }
