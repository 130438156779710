<section id="about" class="about section-bg" #about>
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>About</h2>
        <h3>Find Out More <span>About Us</span></h3>
        <p>BizPrinty simplifies the way custom products are created and lets thousands of ecommerce merchants build profitable businesses.</p>
        <!-- <iframe src='https://webchat.botframework.com/embed/pod-chat-bot?s=P6j3OiZ02cY.rgZs3-q-9nZL5m9ckLews_1--ttTBwxnnUEvir2z-FU'  style='min-width: 400px; width: 100%; min-height: 500px;'></iframe> -->
      </div>

      <div class="row">
        <div class="col-lg-6" data-aos="zoom-out" data-aos-delay="100">
          <img src="assets/img/about.jpg" class="img-fluid" alt="">
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
          <h3>Our mission is to accompany the businesses in every first step of reaching out to the world</h3>
          <p class="font-italic">
            <!-- Upon chasing that quest, we’ve defined our target is to build an eCommerce ecosystem with the following traits: Transparent, Global, Deep Integration & Widely connected to any eCommerce service, hight quality in whatever we do. -->
          </p>
          <ul>
            <li>
              <i class="bx bx-store-alt"></i>
              <div class="command">
                <h5>Your store, your business are our top priorities</h5>
                <!-- <p>BizPrinty now provides a full suite solution for Print on Demand which allow users to manage their orders from multiple marketplaces and platform. Orders are automatically fulfilled and synced back to these marketplaces or platform.</p> -->
              </div>
            </li>
            <li>
              <i class="bx bx-images"></i>
              <div class="command">
                <h5>Your demand, your design are our top services</h5>
                <!-- <p>Develop your idea and create products with ease and publish mockups with custom designs.</p> -->
              </div>
            </li>
          </ul>
          <!-- <p>
            BizPrinty would like to accompany you to gain the very first success in the strategy of penetrating and developing the world market! 
            We are one of the top platform built solely for Print-On-Demand & Dropshipping. This gives us the freedom and flexibility to sharpen our system before moving to the general eCommerce market.
          </p> -->
        </div>
      </div>

    </div>
  </section>