<!-- ======= Top Bar ======= -->
<div id="topbar" class="d-none d-lg-flex align-items-center fixed-top">
    <div class="container d-flex">
      <div class="contact-info mr-auto">
        <i class="icofont-envelope"></i> <a href="mailto:{{contactSetting?contactSetting.email:''}}">{{contactSetting?contactSetting.email:''}}</a>
        <i class="icofont-phone"></i> <a href="tel:{{contactSetting?contactSetting.mobilePhone:''}}">{{contactSetting?contactSetting.mobilePhone:''}}</a>
      </div>
      <div class="social-links">
        <a href="{{contactSetting?contactSetting.twitter:''}}" class="twitter"><i class="icofont-twitter"></i></a>
        <a href="{{contactSetting?contactSetting.facebook:''}}" class="facebook"><i class="icofont-facebook"></i></a>
        <a href="{{contactSetting?contactSetting.instagram:''}}" class="instagram"><i class="icofont-instagram"></i></a>
        <a href="{{contactSetting?contactSetting.skype:''}}" class="skype"><i class="icofont-skype"></i></a>
        <a href="{{contactSetting?contactSetting.linkedin:''}}" class="linkedin"><i class="icofont-linkedin"></i></a>
      </div>
    </div>
  </div>