<section id="team" class="team section-bg" #team>
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>Team</h2>
        <h3>Our Hardworking <span>Team</span></h3>
        <p>We are continuously learning, improving and creating new opportunities for everyone.</p>
      </div>

      <div class="row" *ngIf="teamMembers.length > 0">

        <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100" *ngFor="let member of teamMembers">
          <div class="member">
            <div class="member-img">
              <img src="{{member.image}}" class="img-fluid" alt="{{member.name}}">
              <div class="social">
                <a href="{{member.twitter}}"><i class="icofont-twitter"></i></a>
                <a href="{{member.facebook}}"><i class="icofont-facebook"></i></a>
                <a href="{{member.instagram}}"><i class="icofont-instagram"></i></a>
                <a href="{{member.linkedin}}"><i class="icofont-linkedin"></i></a>
              </div>
            </div>
            <div class="member-info">
              <h4>{{member.name}}</h4>
              <span>{{member.position}}</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>