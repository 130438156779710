import { Component, OnInit } from '@angular/core';
import { Article } from 'src/app/models/article.model';
import { DefaultCategories } from 'src/app/shared/constants/constants';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/ApiEndPoints';
import { PaginationResponse } from 'src/app/models/pagination-response.model';
import * as _ from "lodash";

@Component({
  selector: 'app-skill-section',
  templateUrl: './skill-section.component.html',
  styleUrls: ['./skill-section.component.scss']
})
export class SkillSectionComponent implements OnInit {

  articles: Article[] = [];
  intructionArticleCategory = DefaultCategories["INTRODUCTION-ARTICLE"];

  constructor(private readonly httpBaseService: HttpBaseService, private readonly api: ApiEndPoints) { }

   async ngOnInit() {
    const articlePaging = await this.httpBaseService.getDataAsync<PaginationResponse<Article>>(this.api.getArticles(this.intructionArticleCategory, 'Title', 'asc', 0, 4));
    this.articles = _.orderBy(articlePaging.items, ['index'],['asc']);
  }

  toUpper(text: string){
    return text.toUpperCase();
  }


}
