import { Component, OnInit } from '@angular/core';
import { TeamMember } from 'src/app/models/team-member';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/ApiEndPoints';
import { PaginationResponse } from 'src/app/models/pagination-response.model';
import * as _ from "lodash";

@Component({
  selector: 'app-team-section',
  templateUrl: './team-section.component.html',
  styleUrls: ['./team-section.component.scss']
})
export class TeamSectionComponent implements OnInit {

  teamMembers: TeamMember[] = [];

  constructor(private readonly httpBaseService: HttpBaseService, private readonly api: ApiEndPoints) { }

   async ngOnInit() {
    const teamMemberPaging = await this.httpBaseService.getDataAsync<PaginationResponse<TeamMember>>(this.api.getTeamMembers('Name', 'asc', 0, 4));
    this.teamMembers = teamMemberPaging.items;
  }

}
