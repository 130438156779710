import { Component, OnInit } from '@angular/core';
import { ContactSetting } from 'src/app/models/contact-setting.model';
import { HttpBaseService } from '../../services/http-base.service';
import { ApiEndPoints } from '../../config/ApiEndPoints';
import { DEFAULT_CONTACT_SETTING_ID } from '../../constants/constants';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  contactSetting: ContactSetting;

  constructor(private readonly httpBaseService: HttpBaseService,
    private readonly api: ApiEndPoints,) { }

  async ngOnInit() {
    this.contactSetting = await this.httpBaseService.getDataAsync<ContactSetting>(this.api.getContactSetting(DEFAULT_CONTACT_SETTING_ID));
  }

}
