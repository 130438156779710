<section id="featured-services" class="featured-services">
    <div class="container" data-aos="fade-up">

      <div class="row" *ngIf="articles.length > 0">
        <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" *ngFor="let article of articles">
          <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <!-- <div class="icon"><i class="bx bxl-dribbble"></i></div> -->
            <div class="icon">
              <img src="{{article.ext?article.ext:article.image}}" alt="{{article.title}}" class="benefit-img benefit-img-fix">
            </div>
              <h4 class="title"><a routerLink="/app/article-detail/{{article.id}}">{{toUpper(article.title)}}</a></h4>
            <p class="description">{{article.briefContent}}</p>
          </div>
        </div>
      </div>

    </div>
  </section>
