<section id="skills" class="skills">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <!-- <h2>Our best</h2> -->
        <h3>From online  <span> designs to physical</span> products</h3>
        <!-- <p>Ut possimus qui ut temporibus culpa velit eveniet modi omnis est adipisci expedita at voluptas atque vitae autem.</p> -->
      </div>

      <div class="row" *ngIf="articles.length > 0">
        <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100" *ngFor="let article of articles">
          <div class="icon-box service-card">
            <!-- <div class="icon"> -->
              <!-- <i class="bx bxl-dribbble"></i> -->
                <img src="{{article.ext?article.ext:article.image}}" alt="{{article.title}}" class="benefit-img benefit-img-fix">
        
              <!-- </div> -->
            <h4><a routerLink="/app/article-detail/{{article.id}}">{{toUpper(article.title)}}</a></h4>
            <p>{{article.briefContent}}</p>
          </div>
        </div>
      </div>

    </div>
  </section>