<main id="main" data-aos="fade-up">

    <!-- ======= Breadcrumbs ======= -->
    <section class="breadcrumbs">
      <div class="container" *ngIf="article">

        <div class="d-flex justify-content-between align-items-center">
          <h2>{{article.title}}</h2>
          <ol>
            <li><a href="index.html">Home</a></li>
            <li>{{article.title}}</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->

    <section class="inner-page">
      <div class="container" *ngIf="article">
        <div [innerHTML]="article.fullContent | safeHtml"></div>
      </div>
    </section>

  </main><!-- End #main -->

