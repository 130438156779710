import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { configuration } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiEndPoints {

    constructor(private readonly configurationService: ConfigurationService) { }

    /*************** USER **********************/
    getUserInfo(): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/users/info`;
    }

    becomeSeller(): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/users/become-seller`;
    }


    /*********************** SETTING ***********************/
    getContactSetting(contactId: string): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/setting/contact/${contactId}`;
    }

    getTeamMembers(sortField: string, sortDirection: string, pageIndex: number, pageSize: number): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/setting/team-member/webapp/active/${sortField}/${sortDirection}/${pageIndex}/${pageSize}`;
    }

    getFrequentlyAskQuestions(sortField: string, sortDirection: string, pageIndex: number, pageSize: number): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/setting/frequently-ask-question/webapp/active/${sortField}/${sortDirection}/${pageIndex}/${pageSize}`;
    }
  

    /*********************** ARTICLE ***********************/
    getArticles(categoryId: string, sortField: string, sortDirection: string, pageIndex: number, pageSize: number): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/articles/webapp/${categoryId}/paging/active/${sortField}/${sortDirection}/${pageIndex}/${pageSize}`;
    }

    getMediasForArticle(articleId: string): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/articles/webapp/${articleId}/medias`;
    }

    getDetailsArticleById(articleId: string): string {
        return `${this.configurationService.apiBaseUrl}/api/${configuration.version}/articles/webapp/${articleId}`;
    }

}
