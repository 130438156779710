import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthErrorEvent, OAuthService, NullValidationHandler, UserInfo } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ConfigurationService } from 'src/app/services/configuration.service';
import { HttpBaseService } from '../shared/services/http-base.service';
import { ApiEndPoints } from '../shared/config/ApiEndPoints';

@Injectable({ providedIn: 'root' })

export class AuthService {

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest(
    this.isAuthenticated$,
    this.isDoneLoading$
  ).pipe(map(values => values.every((b => b) as any)));

  private navigateToLoginPage() {
    // TODO: Remember current URL
    this.router.navigateByUrl('/should-login');
  }

  private loadedUserInfoSubject$ = new BehaviorSubject<boolean>(false);
  public loadedUserInfo$ = this.loadedUserInfoSubject$.asObservable();

  userInfo$: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>(undefined);

  constructor(
    private readonly http: HttpBaseService,
    private readonly api: ApiEndPoints,
    private readonly configurationService: ConfigurationService,
    private readonly oauthService: OAuthService,
    private readonly router: Router) {

    // Useful for debugging:
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.error(event);
      } else {
        console.warn(event);
      }
    });

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup.
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events
      .subscribe(_ => {
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      });

    // this.oauthService.events
    //   .pipe(filter(e => ['token_received'].includes(e.type)))
    //   .subscribe(e => this.oauthService.loadUserProfile());

    this.oauthService.events
      .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(e => this.navigateToLoginPage());

    const authConfig: AuthConfig = {
      // Information for this config can be found here:
      // https://manfredsteyer.github.io/angular-oauth2-oidc/docs/overview.html
      clientId: this.configurationService.clientId,
      issuer: this.configurationService.identityServerAddress,
      redirectUri: this.configurationService.redirectUrl,
      responseType: 'token id_token',
      scope: this.configurationService.scope,
      //oath0
      silentRefreshRedirectUri: this.configurationService.silentRefreshUrl,
      timeoutFactor: 0.8,
      //userinfoEndpoint: this.configurationService.identityServerAddress + '/connect/userinfo',
      requestAccessToken: true,
      //adb2c
      skipIssuerCheck: true,
      clearHashAfterLogin: true,
      oidc: true,
      // tokenEndpoint: 'https://bhpt072.b2clogin.com/bhpt072.onmicrosoft.com/oauth2/v2.0/token?p=b2c_1_signupsignin',
      // loginUrl: 'https://bhpt072.b2clogin.com/bhpt072.onmicrosoft.com/oauth2/v2.0/token?p=b2c_1_signupsignin',
      strictDiscoveryDocumentValidation: false,
    };

    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
  }

  documentUrl: string = this.configurationService.identityServerAddress + '.well-known/openid-configuration';

  public runInitialLoginSequence(): Promise<void> {
    if (location.hash) {
      console.log('Encountered hash fragment, plotting as table...');
      console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
    }

    return this.oauthService.loadDiscoveryDocument(this.documentUrl)
      .then(() => {
        this.oauthService.tryLoginImplicitFlow().then(() => {
          if (this.oauthService.hasValidAccessToken()) {
            this.isAuthenticatedSubject$.next(true);
            this.getUerProfile();
            return Promise.resolve();
          }
        })
          .then(() => {
            this.isDoneLoadingSubject$.next(true);
            if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
              console.log('There was state, so we are sending you to: ' + this.oauthService.state);
              this.router.navigateByUrl('/app');
            }
          })
          .catch(() => {
            this.isDoneLoadingSubject$.next(true)
          });
      })
  }

  public loadDiscoveryDocumentAndTryLogin() {
    return this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  public login(targetUrl?: string) {
    this.oauthService.initLoginFlow(encodeURIComponent(targetUrl || this.router.url));
  }

  public tryLoginImplicitFlow() {
    return this.oauthService.tryLoginImplicitFlow();
  }

  public logout() {
    this.oauthService.logOut();
    localStorage.clear();
    window.location.reload();
  }

  public refresh() { this.oauthService.silentRefresh(); }
  public hasValidToken() { return this.oauthService.hasValidAccessToken(); }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() { return this.oauthService.getAccessToken(); }

  public get identityClaims() { return this.oauthService.getIdentityClaims(); }

  public get idToken() { return this.oauthService.getIdToken(); }

  public get logoutUrl() { return this.oauthService.logoutUrl; }

  private getUerProfile() {
    this.http.getDataAsync<UserInfo>(this.api.getUserInfo()).then((user) => {
      this.userInfo$.next(user);
    })
      .finally(() => this.loadedUserInfoSubject$.next(true))
  }
}
