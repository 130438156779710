import { Component, OnInit } from '@angular/core';
import { ContactSetting } from 'src/app/models/contact-setting.model';
import { ApiEndPoints } from 'src/app/shared/config/ApiEndPoints';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { DEFAULT_CONTACT_SETTING_ID } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.scss']
})
export class ContactSectionComponent implements OnInit {

  contactSetting: ContactSetting;

  constructor(private readonly httpBaseService: HttpBaseService, private readonly api: ApiEndPoints) { }

  async ngOnInit() {
    this.contactSetting = await this.httpBaseService.getDataAsync<ContactSetting>(this.api.getContactSetting(DEFAULT_CONTACT_SETTING_ID));
  }

}
