<main id="main">
  <app-intro></app-intro>
  <!-- ======= Featured Services Section ======= -->
  <app-featured-service></app-featured-service>
  <!-- End Featured Services Section -->

  <!-- ======= About Section ======= -->
  <app-about-section></app-about-section>
  <!-- End About Section -->

  <!-- ======= Counts Section ======= -->
  <!-- <app-count-section></app-count-section> -->
  <!-- End Counts Section -->

  <!-- ======= Clients Section ======= -->
  <!-- <app-client-section></app-client-section> -->
  <!-- End Clients Section -->

  <!-- ======= Services Section ======= -->
  <app-service-section></app-service-section>
  <!-- End Services Section -->

  <!-- ======= Testimonials Section ======= -->
  <app-testimonial-section></app-testimonial-section>
  <!-- End Testimonials Section -->

  <!-- ======= Portfolio Section ======= -->
  <app-portfolio></app-portfolio>
  <!-- End Portfolio Section -->

  <!-- ======= Skills Section ======= -->
  <app-skill-section></app-skill-section>
  <!-- End Skills Section -->

  <!-- ======= Team Section ======= -->
  <app-team-section></app-team-section>
  <!-- End Team Section -->

  <!-- ======= Pricing Section ======= -->
  <!-- <app-pricing-section></app-pricing-section>-->
  <!-- End Pricing Section -->

  <!-- ======= Frequently Asked Questions Section ======= -->
  <app-frequently-ask></app-frequently-ask>
  <!-- End Frequently Asked Questions Section -->

  <!-- ======= Contact Section ======= -->
  <app-contact-section></app-contact-section>
  <!-- End Contact Section -->

</main>
<!-- End #main -->