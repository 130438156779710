import { Component, OnInit } from '@angular/core';
import { FrequentlyAskQuestion } from 'src/app/models/frequently-ask-question.model';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/ApiEndPoints';
import { PaginationResponse } from 'src/app/models/pagination-response.model';

@Component({
  selector: 'app-frequently-ask',
  templateUrl: './frequently-ask.component.html',
  styleUrls: ['./frequently-ask.component.scss']
})
export class FrequentlyAskComponent implements OnInit {

  questions: FrequentlyAskQuestion[] = [];

  constructor(private readonly httpBaseService: HttpBaseService, private readonly api: ApiEndPoints) { }

   async ngOnInit() {
    const paging = await this.httpBaseService.getDataAsync<PaginationResponse<FrequentlyAskQuestion>>(this.api.getFrequentlyAskQuestions('Name', 'asc', 0, 10));
    this.questions = paging.items;
  }


}
