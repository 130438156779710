import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/ApiEndPoints';
import { Subscription, combineLatest } from 'rxjs';
import { Article } from 'src/app/models/article.model';
import { Media } from 'src/app/models/media.model';

@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.scss']
})
export class ArticleDetailComponent implements OnInit, OnDestroy {

  articleId: string;
  article: Article;
  medias: Media[] = [];
  private paramsSubscription!: Subscription;
  
  constructor(private readonly _activeRouter: ActivatedRoute,
    private readonly _httpService: HttpBaseService,
    private readonly _api: ApiEndPoints) { }

  async ngOnInit() {
    this.paramsSubscription = combineLatest(this._activeRouter.params,
      (params) => ({ params })).subscribe(async allParams => {

        let params = allParams.params;
        const id = params['id'];
        this.articleId = id;
        await this.loadArticleDetail(id);
        await this.loadMediasForArticle(id);
      });
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }


  async loadArticleDetail(articleId: string) {
    this.article = await this._httpService.getDataAsync<Article>(this._api.getDetailsArticleById(articleId));
  }

  async loadMediasForArticle(articleId: string) {
    this.medias = await this._httpService.getDataAsync<Media[]>(this._api.getMediasForArticle(articleId));
  }

}
